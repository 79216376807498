<template>
  <div v-if="franchise.id && $can('read', 'Franchise')">
    <!-- DIRECTOR(S) -->
    <b-row>
      <b-col cols="12" md="3"><b>Director(s):</b></b-col>
      <b-col>
        <template v-for="(director, index) in franchise.director">
          <a
            href="javascript:;"
            @click="$store.commit('SET_USER_PROFILE_ID', director.id)"
            :key="index"
          >
            {{ `${director.profile.fname} ${director.profile.lname}` }} </a
          ><span v-if="index !== franchise.director.length - 1" :key="'del-' + index"
            >,
          </span>
        </template>
      </b-col>
    </b-row>

    <hr class="mt-4" />

    <!-- info Sections -->
    <template v-for="(infoSection, index) in infoSections">
      <template v-if="$can('read', infoSection.accessItem)">
        <h4 :key="'infoSection-' + index + '-label'">{{ infoSection.label }}</h4>
        <div :key="'infoSection-' + index" class="mb-3">
          <div
            v-if="!Array.isArray(infoSection.data)"
            v-html="
              infoSection.filter
                ? $options.filters[filter](infoSection.data)
                : infoSection.data
            "
          ></div>
          <div v-else>
            <b-row
              v-for="(infoSectionData, index2) in infoSection.data"
              :class="'section-info-' + (index2 % 2 == 0 ? 'even' : 'odd')"
              :key="index2"
            >
              <b-col cols="12" md="3"
                ><b>{{ infoSectionData.label }}:</b></b-col
              >
              <b-col
                v-if="!Array.isArray(infoSectionData.value)"
                v-html="infoSectionData.value"
              ></b-col>
              <b-col v-else v-html="infoSectionData.value.join(' ')"></b-col>
            </b-row>
          </div>
        </div>
        <hr
          v-if="infoSections.length - 1 > index"
          class="mt-4"
          :key="'infoSection-' + index + '-hr'"
        />
      </template>
    </template>

    <b-card
      v-if="
        franchise.director.find(({ id }) => id === currentUser.id) &&
        $can('read', 'SSC Note')
      "
      title="SSC Notes"
    >
      <sscNotes :franchiseCode="code" />
    </b-card>
    <b-card
      v-if="canEditFranchise && $can('read', 'Franchise Docs')"
      title="Uploaded Docs"
      >TODO</b-card
    >
    <b-card v-if="$can('manage', 'Franchise Functions')" title="Migration Functions"
      >TODO</b-card
    >
  </div>
  <p v-else class="text-center">
    <b-spinner small type="grow" class="mr-2" :label="$t('label.loading')"></b-spinner>
    {{ $t('label.loading') }}
  </p>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import sscNotes from '../ssc/~SscNotes.vue'
const ADMIN_ROLES = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']
const DATE_FORMAT = 'MM/DD/YYYY'
const SERVICE_LEVELS = ['Pending', 'Independent', 'No-SSC', 'Z! Hub SSC', 'Alternate SSC']
export default {
  name: 'FranchiseDetails',
  components: { sscNotes },
  props: {
    franchiseCode: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    serviceLevels: SERVICE_LEVELS,
    code: null,
  }),
  computed: {
    ...mapGetters(['franchise']),
    canEditFranchise() {
      for (let i in this.currentUser.roles) {
        if (ADMIN_ROLES.includes(this.currentUser.roles[i])) {
          return true
        }
      }
      return this.franchise.director.find(({ id }) => id === this.currentUser.id)
    },
    infoSections() {
      // @TODO move to separate file?
      const infoSections = []
      // General
      infoSections.push({
        label: 'General',
        accessItem: 'Franchise',
        data: [
          { label: this.$t('label.franchiseCode'), value: this.franchise.franchiseCode },
          { label: 'Main Area', value: this.franchise.mainArea },
          { label: 'Status', value: !this.franchise.active ? 'Inactive' : 'Active' },
          { label: 'Service', value: this.serviceLevels[this.franchise.level] },
          {
            label: 'Mailing Address',
            value: [
              this.franchise.mailingAddress1,
              this.franchise.mailingAddress2,
              this.franchise.city,
              this.franchise.state,
              this.franchise.zipCode,
              this.franchise.country,
            ],
          },
          { label: 'Email', value: this.serviceLevels[this.franchise.email] },
          {
            label: 'Phone',
            value: this.franchise.phones
              ? this.franchise.phones.map((e) => e.number).join('<br />')
              : null,
          },
          {
            label: 'URLs',
            value: this.franchise.webUrl
              ? this.$options.filters.linkUrls(
                  this.franchise.webUrl.split('\n').join('<br />')
                )
              : null,
          },
          { label: 'Invoices', value: ['Paid', 'Unpaid'].join(', ') },
        ],
      })

      // Territories
      infoSections.push({
        label: 'Territories',
        accessItem: 'Zip Code',
        data: [
          {
            label: 'Protected',
            value: this.franchise.territories
              .filter((e) => e.status && e.status.id === 1)
              .map((e) => e.zipCode)
              .join(', '),
          },
          {
            label: 'Unprotected',
            value: this.franchise.territories
              .filter((e) => e.status && e.status.id === 2)
              .map((e) => e.zipCode)
              .join(', '),
          },
        ],
      })

      // Notes
      // if (this.currentUser.roles.includes('ROLE_ADMIN')) {
      infoSections.push({
        label: 'Notes',
        accessItem: 'Franchise Notes',
        data: this.format(this.franchise.notes, 'nl2br'),
      })
      // }

      // Dates
      // if (this.currentUser.roles.includes('ROLE_ADMIN')) {
      infoSections.push({
        label: 'Dates',
        accessItem: 'Franchise Dates',
        data: [
          {
            label: 'Purchased',
            value: this.$d(this.franchise.purchased).format(DATE_FORMAT),
          },
          {
            label: 'Trained',
            value: this.$d(this.franchise.trained).format(DATE_FORMAT),
          },
          {
            label: 'Royalties Begin',
            value: this.$d(this.franchise.royaltiesBegin).format(DATE_FORMAT),
          },
          {
            label: 'Renews',
            value: this.$d(this.franchise.renewDate).format(DATE_FORMAT),
          },
        ],
      })
      // }

      // Fees
      if (this.currentUser.roles.includes('ROLE_ACCOUNTING')) {
        infoSections.push({
          label: 'Fees',
          accessItem: 'Franchise Fees',
          data: [
            {
              label: 'Royalty Sliding Scale',
              value: this.franchise.royaltySlidingScale || '@TODO: display default',
            },
            {
              label: 'Royalty Percentage(s)',
              value: this.franchise.royaltyPercentage
                ? this.franchise.royaltyPercentage.filter((e) => e != '').join(' / ')
                : null,
            },
            {
              label: 'Unp. Royalty Percentage',
              value: this.franchise.royaltyPercentageU
                ? this.franchise.royaltyPercentageU[0]
                : null,
            },
            {
              label: 'Min Royalty Amnt',
              value: this.format(this.franchise.minimumRoyalty, 'formatMoney'),
            },
            {
              label: 'Min Advertising Spend',
              value: this.format(this.franchise.minimumAdvSpend, 'formatMoney'),
            },
            {
              label: 'Monthly Software Fee',
              value: this.format(this.franchise.softwareFee, 'formatMoney'),
            },
            {
              label: 'Monthly Marketing Fee',
              value: this.format(this.franchise.marketingFee, 'formatMoney'),
            },
          ],
        })
      }

      return infoSections
    },
  },
  methods: {
    ...mapActions(['resetFranchise', 'getFranchise', 'resetSscNotes']),
    format(value, filter) {
      if ((value, !filter)) {
        return value
      }
      return this.$options.filters[filter](value)
    },
  },
  watch: {
    franchiseCode() {
      this.resetFranchise()
    },
    '$route.params.franchiseCode'() {
      this.resetFranchise()
    },
  },
  async mounted() {
    if (this.franchiseCode || this.$route.params.franchiseCode) {
      this.code = this.franchiseCode || this.$route.params.franchiseCode
      // @TODO this.resetSscNotes()
      await this.getFranchise(this.code)
    }
  },
}
</script>

<style scoped>
.section-info-even {
  background-color: #faf9f6;
}
.section-info-odd {
  background-color: white;
}
</style>
