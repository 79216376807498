<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!-- h1><img class="mt-2" src="https://clubztutoring.com/wp-content/uploads/2018/04/logo-1.png" alt="Club Z!" /></h1 -->
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        src="https://clubztutoring.com/wp-content/uploads/2018/04/logo-1.png"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        src="/img/logo-symbol.f1b035f6.png"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="userNav" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import store from '../store'
// import CSidebarNavItem from './template/CZSidebarNavItem'

export default {
  name: 'TheSidebar',
  computed: {
    userNav() {
      return nav[store.getters.userRole] || []
    },
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
  },
  watch: {
    minimize(val) {
      localStorage.setItem('sidebarMinimize', val)
    },
  },
  created() {
    const sidebarMinimize = localStorage.getItem('sidebarMinimize')
    this.$store.commit('set', ['sidebarMinimize', sidebarMinimize === 'true'])
  },
}
</script>

<style>
.brand {
  background-image: url(../assets/img/logo-symbol.png);
}
</style>
