import { render, staticRenderFns } from "./FranchiseDetails.vue?vue&type=template&id=71a5970a&scoped=true&"
import script from "./FranchiseDetails.vue?vue&type=script&lang=js&"
export * from "./FranchiseDetails.vue?vue&type=script&lang=js&"
import style0 from "./FranchiseDetails.vue?vue&type=style&index=0&id=71a5970a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a5970a",
  null
  
)

export default component.exports