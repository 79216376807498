<template>
  <div>
    <b-modal
      v-for="(modal, index) in modals"
      :size="modal.size || 'md'"
      :id="modalId(index)"
      :title="modal.title || null"
      :key="index"
      hide-footer
      centered
      @hide="onHide(index)"
    >
      <div :class="modal.bodyClass || null" v-html="modal.content"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const PREFIX_ID = 'site-modal-'
export default {
  name: 'Modals',
  computed: {
    ...mapGetters([
      'modals'
    ])
  },
  methods: {
    ...mapActions([
      'removeModal'
    ]),
    modalId (i) {
      return PREFIX_ID + i
    },
    async onHide (i) {
      await this.$bvModal.hide(this.modalId(i))
      this.removeModal(i)
    }
  },
  watch: {
    modals (val) {
      for (let i in val) {
        setTimeout(() => {
          this.$bvModal.show(this.modalId(i))
        }, 250)
      }
    }
  }
}
</script>
