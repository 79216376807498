var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.franchise.id && _vm.$can('read', 'Franchise'))?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b',[_vm._v("Director(s):")])]),_c('b-col',[_vm._l((_vm.franchise.director),function(director,index){return [_c('a',{key:index,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$store.commit('SET_USER_PROFILE_ID', director.id)}}},[_vm._v(" "+_vm._s(((director.profile.fname) + " " + (director.profile.lname)))+" ")]),(index !== _vm.franchise.director.length - 1)?_c('span',{key:'del-' + index},[_vm._v(", ")]):_vm._e()]})],2)],1),_c('hr',{staticClass:"mt-4"}),_vm._l((_vm.infoSections),function(infoSection,index){return [(_vm.$can('read', infoSection.accessItem))?[_c('h4',{key:'infoSection-' + index + '-label'},[_vm._v(_vm._s(infoSection.label))]),_c('div',{key:'infoSection-' + index,staticClass:"mb-3"},[(!Array.isArray(infoSection.data))?_c('div',{domProps:{"innerHTML":_vm._s(
            infoSection.filter
              ? _vm.$options.filters[_vm.filter](infoSection.data)
              : infoSection.data
          )}}):_c('div',_vm._l((infoSection.data),function(infoSectionData,index2){return _c('b-row',{key:index2,class:'section-info-' + (index2 % 2 == 0 ? 'even' : 'odd')},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b',[_vm._v(_vm._s(infoSectionData.label)+":")])]),(!Array.isArray(infoSectionData.value))?_c('b-col',{domProps:{"innerHTML":_vm._s(infoSectionData.value)}}):_c('b-col',{domProps:{"innerHTML":_vm._s(infoSectionData.value.join(' '))}})],1)}),1)]),(_vm.infoSections.length - 1 > index)?_c('hr',{key:'infoSection-' + index + '-hr',staticClass:"mt-4"}):_vm._e()]:_vm._e()]}),(
      _vm.franchise.director.find(function (ref) {
          var id = ref.id;

          return id === _vm.currentUser.id;
}) &&
      _vm.$can('read', 'SSC Note')
    )?_c('b-card',{attrs:{"title":"SSC Notes"}},[_c('sscNotes',{attrs:{"franchiseCode":_vm.code}})],1):_vm._e(),(_vm.canEditFranchise && _vm.$can('read', 'Franchise Docs'))?_c('b-card',{attrs:{"title":"Uploaded Docs"}},[_vm._v("TODO")]):_vm._e(),(_vm.$can('manage', 'Franchise Functions'))?_c('b-card',{attrs:{"title":"Migration Functions"}},[_vm._v("TODO")]):_vm._e()],2):_c('p',{staticClass:"text-center"},[_c('b-spinner',{staticClass:"mr-2",attrs:{"small":"","type":"grow","label":_vm.$t('label.loading')}}),_vm._v(" "+_vm._s(_vm.$t('label.loading'))+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }