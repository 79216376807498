<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="py-0"
  >
    <template #toggler>
      <CHeaderNavLink class="btn btn-link btn-sm float-right pt-1" :title="currentLangData.text">
        <flag :iso="currentLangData.flag" v-bind:squared=false />
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="switchLocale('en')"><flag iso="us" v-bind:squared=false /> English </CDropdownItem>
    <CDropdownItem @click="switchLocale('es')"><flag iso="es" v-bind:squared=false /> Española </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      lang: null,
      langOpts: [
        { lang: 'en', text: 'English', flag: 'us' },
        { lang: 'es', text: 'Española', flag: 'es' }
      ]
    }
  },
  computed: {
    currentLangData () {
      return this.langOpts.find(({ lang }) => lang === this.lang )
    }
  },
  methods: {
    switchLocale (val) {
      this.$i18n.locale = val
      localStorage.setItem('locale', val)
      window.location.reload()
    }
  },
  created () {
    this.lang = this.$i18n.locale
  }
}
</script>
