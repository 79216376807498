<template>
  <b-modal ref="user-profile-modal" id="user-profile-modal" title="User Profile" ok-title="Close" @hide="$emit('hide')"
    ok-only>
    <div v-if="isViewing" class="page-content page-container" id="page-content">
      <div class="padding">
        <b-row class="d-flex justify-content-center">
          <b-col cols="12" lg="12">
            <b-card class="user-card-full">
              <b-row class="ml-0 mr-0">
                <b-col cols="12" md="12" class="bg-c-lite-green user-profile">
                  <b-row class="card-block text-center text-white">
                    <b-col cols="12" md="6">
                      <div class="mb-0" style="position: relative;">
                        <b-img :src="theUser.image || 'https://img.icons8.com/bubbles/100/000000/user.png'"
                          class="img-radius" alt="User-Profile-Image" rounded="circle" fluid />
                        <b-button-group v-if="theUser.id === $store.getters.currentUser.id"
                          style="position:absolute;bottom:0;left:0" size="sm">
                          <b-button @click="onUpdateImage()" v-b-tooltip.hover title="Update Image">
                            <b-icon-camera></b-icon-camera>
                          </b-button>
                          <b-button v-if="theUser.image" variant="danger" @click="onRemoveProfileImage()"
                            v-b-tooltip.hover title="Remove Image">
                            <b-icon-dash></b-icon-dash>
                          </b-button>
                        </b-button-group>
                      </div>
                    </b-col>
                    <b-col cols="12" md="6">
                      <h6 class="f-w-600">{{ theUser.name }}</h6>
                      <p><b v-if="!theUser.active" class="text-danger">Incative</b>
                        <span v-else>Active</span> {{ theUser.title }}
                      </p>
                      <p v-if="canImpersonate && currentUser.id !== theUser.id">
                        <a href="javascript:;" class="text-white" @click="impersonate(theUser.username)">Impersonate
                          User</a>
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" md="12">
                  <div class="card-block">
                    <h6 class="mb-4 pb-3 b-b-default f-w-600">Contact Info</h6>
                    <b-row>
                      <b-col cols="9">
                        <p class="mb-2 f-w-600">Email</p>
                        <h6 class="text-muted f-w-400">{{ theUser.email }}</h6>
                      </b-col>
                      <b-col cols="3">
                        <p class="mb-3 f-w-600">Ext</p>
                        <h6 class="text-muted f-w-400">{{ theUser.ext }}</h6>
                      </b-col>
                    </b-row>
                    <!-- 
                    <h6 class="mb-4 m-t-40 pb-1 b-b-default f-w-600">Stats</h6>
                    <b-row>
                        <b-col cols="6">
                            <p class="mb-3 f-w-600">Taken</p>
                            <h6 class="text-muted f-w-400">342</h6>
                        </b-col>
                        <b-col cols="6">
                            <p class="mb-3 f-w-600">Booked</p>
                            <h6 class="text-muted f-w-400">298</h6>
                        </b-col>
                    </b-row>
                    <ul class="social-link list-unstyled m-t-40 mb-3">
                        <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="facebook" data-abc="true"><b-icon-facebook></b-icon-facebook></a></li>
                        <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="twitter" data-abc="true"><b-icon-twitter></b-icon-twitter></a></li>
                        <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="instagram" data-abc="true"><b-icon-instagram></b-icon-instagram></a></li>
                    </ul> -->
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <a href="javascript:;" v-if="!isViewing" @click="currentStatus = 0" class="m-4 mt-5">Back To Profile</a>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { constants } from '@/shared/constants'
const STATUS_VIEWING = 0
const STATUS_EDIT_DATA = 2
const USER_DEFAULTS = {
  id: null,
  username: null,
  name: null,
  active: null,
  title: null,
  email: null,
  image: null,
  ext: '123'
}
export default {
  name: 'UserProfile',
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      currentStatus: null,
      imageDirUserProfile: constants.IMAGE_DIR_USER_PROFILE
    }
  },
  computed: {
    theUser() {
      return { ...USER_DEFAULTS, ...this.user }
    },
    isViewing() {
      return this.currentStatus === STATUS_VIEWING
    },
    isEditing() {
      return this.currentStatus === STATUS_EDIT_DATA
    }
  },
  methods: {
    ...mapActions([
      'impersonate'
    ]),
    onUpdateImage() {
      this.$router.push({ name: 'UserProfileImageEdit', params: { user: this.theUser.id } })
      this.$bvModal.hide('user-profile-modal')
    },
    onRemoveProfileImage() {
      this.$bvModal.msgBoxConfirm('Remove your profile image?').then(confirm => {
        if (!confirm) return
        this.putUserProfileImage(null)
      })
    }
  },
  watch: {
    user: {
      deep: true,
      handler(val) {
        if (val.id) {
          this.$bvModal.show('user-profile-modal')
        } else {
          this.$bvModal.hide('user-profile-modal')
        }
      }
    }
  },
  mounted() {
    this.currentStatus = STATUS_VIEWING
  }
}
</script>

<style lang="scss" scoped>
.user-card-full {
  overflow: hidden;
  margin-bottom: 0 !important;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
  background: -webkit-gradient(linear, left top, right top, from(#7CC24D), to(#639B3D));
  background: linear-gradient(to right, #639B3D, #7CC24D)
}

.user-profile {
  padding: 20px 0
}

.card-block {
  padding: 1.25rem
}

.img-radius {
  border-radius: 5px
}

h6 {
  font-size: 14px
}

.card .card-block p {
  line-height: 25px
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px
  }
}

.card-block {
  padding: 1.25rem
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0
}

.card .card-block p {
  line-height: 25px
}

.text-muted {
  color: #919aa3 !important
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
  font-weight: 600
}

.user-card-full .social-link li {
  display: inline-block
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out
}
</style>