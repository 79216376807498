<template>
  <div>
    <!-- Groups List -->
    <b-modal
      id="modal-client-user-groups"
      size="sm"
      @hide="onHideModalGroups()"
      hide-footer
      centered
    >
      <template v-if="modalGroupsData" #modal-title>
        {{ modalGroupsData.name }}'s Group(s)
      </template>
      <template v-if="modalGroupsData">
        <b-button
          v-for="thisGroup in modalGroupsData.groups"
          variant="info"
          :key="thisGroup.group.id"
          @click="showGroupDetails(thisGroup.group.id)"
          block
        >
          {{
            thisGroup.group.label
              ? `${thisGroup.group.type.label}: ${thisGroup.group.label}`
              : thisGroup.group.type.label
          }}
        </b-button>
      </template>
    </b-modal>

    <!-- Group Details -->
    <b-modal
      v-if="modalGroupsData"
      id="modal-group-details"
      size="sm"
      @hide="onHideModalGroup()"
      ok-only
    >
      <template v-if="group.id" #modal-title>
        {{ group.label || group.type.label }}
      </template>
      <template v-if="group">
        <p>{{ group.details }}</p>
        <div v-if="groupContacts.length">
          <div><b>Contact:</b></div>
          <p v-for="contact in groupContacts" :key="contact.data.id">
            <b>{{ contact.data.relationship }}</b
            >: {{ contact.data.fname }} {{ contact.data.lname }}<br />
            <a :href="'mailto:' + contact.data.email">{{ contact.data.email }}</a>
          </p>
        </div>
        <div v-if="group.users && group.users.length">
          <div><b>Users:</b></div>
          <p v-for="groupUser in group.users" :key="groupUser.user.id">
            <router-link :to="{ name: 'UserForm', query: { uid: groupUser.user.id } }"
              >{{ groupUser.user.profile.fname }}
              {{ groupUser.user.profile.lname }}</router-link
            >
          </p>
        </div>
        <div v-if="group.clients && group.clients.length">
          <div><b>Clients:</b></div>
          <p v-for="groupClient in group.clients" :key="groupClient.client.id">
            <router-link
              :to="{ name: 'ClientForm', query: { cid: groupClient.client.id } }"
              >{{ groupClient.client.fname }} {{ groupClient.client.lname }}</router-link
            >
          </p>
        </div>
        <div>
          <b-button-group size="sm" class="pt-3 border-top mx-auto">
            <b-button v-if="group.type" variant="link" @click="hideModalGroups()"
              >View {{ group.type.label }}</b-button
            >
            <b-button variant="link" @click="hideModalGroups()">Add User</b-button>
            <b-button
              variant="link"
              :to="{ name: 'ClientForm', query: { gid: group.id } }"
              @click="hideModalGroups()"
              >Add Client</b-button
            >
          </b-button-group>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalGroups',
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapGetters(['modalGroupsData', 'group', 'groupContacts']),
    // selectedGroup () {
    //   if (!this.group) {
    //     return null
    //   }
    //   const group = {}
    //   if (this.group.users && this.group.users.length) {
    //     group.users = this.group.users.map(e => `${e.user.profile.fname} ${e.user.profile.lname}`).join('<br>')
    //   }
    //   if (this.group.clients && this.group.clients.length) {
    //     group.clients = this.group.clients.map(e => `${e.client.fname} ${e.client.lname}`).join('<br>')
    //   }
    //   return group
    // }
  },
  watch: {
    async modalGroupsData(val) {
      if (val) {
        if (val.groups.length > 1) {
          this.$bvModal.show('modal-client-user-groups')
        } else {
          await this.setGroupData(val.groups[0].group.id)
        }
      } else {
        this.$bvModal.hide('modal-client-user-groups')
        this.resetContactRecords()
      }
    },
    group(val) {
      if (!val.id) {
        this.$bvModal.hide('modal-group-details')
      } else {
        this.$bvModal.show('modal-group-details')
      }
    },
  },
  methods: {
    ...mapActions([
      'hideModalGroups',
      'getContactRecords',
      'getGroup',
      'resetGroup',
      'resetContactRecords',
    ]),
    async setGroupData(groupId) {
      this.loading = true
      const group = await this.getGroup(groupId)
      await this.getContactRecords({ groupId })
      this.loading = false
      return group
    },
    async showGroupDetails(id) {
      await this.getGroup(id)
    },
    onHideModalGroup() {
      this.resetGroup()
      this.resetContactRecords()
    },
    onHideModalGroups() {
      this.hideModalGroups()
    },
  },
}
</script>
