<template>
  <CFooter :fixed="false">
    <div>
      {{ $store.getters.appName }} v{{ $store.getters.appVersion }} 
      <span class="ml-1">(&copy; {{ new Date().getFullYear() }} <a href="https://clubztutoring.com" target="_blank">Club Z! Inc</a>)</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
