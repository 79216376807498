<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <h1>
        <img
          class="mt-2"
          src="https://clubztutoring.com/wp-content/uploads/2018/04/logo-1.png"
          alt="Club Z!"
        />
      </h1>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>
    <CHeaderNav class="mr-4">
      <!-- CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          {{$i18n.locale}}
        </CHeaderNavLink>
      </CHeaderNavItem -->
      <!-- CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem -->
      <CHeaderNavItem v-if="currentUser && currentUser.isImpersonating" class="mx-2">
        <a
          title="Stop Impersonating"
          href="javascript:;"
          class="text-danger"
          @click="$store.dispatch('impersonateExit')"
        >
          <b-icon-person-circle></b-icon-person-circle>
        </a>
      </CHeaderNavItem>
      <CHeaderNavItem class="ml-4 d-md-down-none">
        <b class="text-success">{{ currentUserProfileName }}</b>
      </CHeaderNavItem>
      <LocaleSwitcher class="mx-3 d-md-down-none" />
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <h3 style="padding: 0.75rem 1rem" class="float-left mb-0 p-2">
        <span v-if="$route.meta.title || $route.meta.label">{{
          $route.meta.title || $route.meta.label
        }}</span>
      </h3>
      <CBreadcrumbRouter class="d-md-down-none d-lg-flex border-0 mb-0 float-right" />
      <Working />
    </CSubheader>
  </CHeader>
</template>

<script>
import LocaleSwitcher from './LocaleSwitcher'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import Working from './Working'

export default {
  name: 'TheHeader',
  components: {
    LocaleSwitcher,
    TheHeaderDropdownAccnt,
    Working,
  },
}
</script>
