<template>
  <div>
    <div
      v-if="$store.getters.isLoading"
      id="loading-Overlay"
      class="d-flex justify-content-center pt-5"
    >
      <!-- working spinner -->
      <div class="loading-indicator bg-info p-2 mx-auto mt-3 mb-auto"><b-spinner type="grow" :label="$t('label.loading')" small></b-spinner> {{ $t('label.loading') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Working'
}
</script>

<style lang="scss" scoped>
  #loading-Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 999999;
    opacity: 0.7
  }
</style>
