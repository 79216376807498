import i18n from '@/plugins/i18n'
// @TODO set roles per item, opposed to items per role
export default {
  ROLE_SUPER_ADMIN: [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('nav.dashboard'),
          to: '/dashboard',
          icon: 'cil-speedometer'
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.ssc.ssc'),
          route: '/ssc',
          icon: 'cil-phone',
          items: [
            {
              name: i18n.t('nav.ssc.booking'),
              to: '/ssc/booking'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.dates'),
          to: '/events',
          icon: 'cil-calendar',
          items: [
            {
              name: i18n.t('nav.calendar'),
              to: '/events/calendar'
            },
            {
              name: 'Enrollments',
              to: '/events/appointments/enrollment'
            },
            {
              name: 'Sessions',
              to: '/events/appointments/session'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.franchise.franchise'),
          route: '/franchise',
          icon: 'cil-address-book',
          items: [
            {
              name: i18n.t('nav.franchise.list'),
              to: '/franchise/directory'
            }
          ]
        },
        // {
        //   _name: 'CSidebarNavDropdown',
        //   name: i18n.t('nav.reporting.reporting'),
        //   route: '/reporting',
        //   icon: 'cil-chart-pie',
        //   items: [
        //     {
        //       name: i18n.t('nav.reporting.reporting'),
        //       to: '/reporting'
        //     }
        //   ]
        // },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.leads.leads'),
          route: '/leads',
          icon: 'cil-list',
          items: [
            {
              name: i18n.t('nav.leads.list'),
              to: '/leads/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/leads/form'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.clients.clients'),
          route: '/clients',
          icon: 'cil-people',
          items: [
            {
              name: i18n.t('nav.clients.list'),
              to: '/clients/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/clients/form'
            },
            {
              name: i18n.t('nav.common.billing'),
              to: { name: 'BillingList' }
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.system.system'),
          route: '/system',
          icon: 'cil-globe-alt',
          items: [
            {
              name: i18n.t('nav.system.users'),
              to: '/system/users'
            },
            {
              name: i18n.t('nav.system.preferences'),
              to: '/'
            }
          ]
        }
      ]
    }
  ],
  ROLE_ADMIN: [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('nav.dashboard'),
          to: '/dashboard',
          icon: 'cil-speedometer'
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.ssc.ssc'),
          route: '/ssc',
          icon: 'cil-phone',
          items: [
            {
              name: i18n.t('nav.ssc.booking'),
              to: '/ssc/booking'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.dates'),
          to: '/events',
          icon: 'cil-calendar',
          items: [
            {
              name: i18n.t('nav.calendar'),
              to: '/events/calendar'
            },
            {
              name: 'Enrollments',
              to: '/events/appointments/enrollment'
            },
            {
              name: 'Sessions',
              to: '/events/appointments/session'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.franchise.franchise'),
          route: '/franchise',
          icon: 'cil-address-book',
          items: [
            {
              name: i18n.t('nav.franchise.list'),
              to: '/franchise/directory'
            }
          ]
        },
        // {
        //   _name: 'CSidebarNavDropdown',
        //   name: i18n.t('nav.reporting.reporting'),
        //   route: '/reporting',
        //   icon: 'cil-chart-pie',
        //   items: [
        //     {
        //       name: i18n.t('nav.reporting.reporting'),
        //       to: '/reporting'
        //     }
        //   ]
        // },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.leads.leads'),
          route: '/leads',
          icon: 'cil-list',
          items: [
            {
              name: i18n.t('nav.leads.list'),
              to: '/leads/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/leads/form'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.clients.clients'),
          route: '/clients',
          icon: 'cil-people',
          items: [
            {
              name: i18n.t('nav.clients.list'),
              to: '/clients/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/clients/form'
            },
            {
              name: i18n.t('nav.common.billing'),
              to: { name: 'BillingList' }
            }
          ]
        }
      ]
    }
  ],
  ROLE_SSC_ADMIN: [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.ssc.ssc'),
          route: '/ssc',
          icon: 'cil-phone',
          items: [
            {
              name: i18n.t('nav.ssc.booking'),
              to: '/ssc/booking'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.dates'),
          to: '/events',
          icon: 'cil-calendar',
          items: [
            {
              name: i18n.t('nav.calendar'),
              to: '/events/calendar'
            },
            {
              name: 'Enrollments',
              to: '/events/appointments/enrollment'
            },
            {
              name: 'Sessions',
              to: '/events/appointments/session'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.franchise.franchise'),
          route: '/franchise',
          icon: 'cil-address-book',
          items: [
            {
              name: i18n.t('nav.franchise.list'),
              to: '/franchise/directory'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.leads.leads'),
          route: '/leads',
          icon: 'cil-list',
          items: [
            {
              name: i18n.t('nav.leads.list'),
              to: '/leads/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/leads/form'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.clients.clients'),
          route: '/clients',
          icon: 'cil-people',
          items: [
            {
              name: i18n.t('nav.clients.list'),
              to: '/clients/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/clients/form'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.system.system'),
          route: '/system',
          icon: 'cil-globe-alt',
          items: [
            {
              name: i18n.t('nav.system.users'),
              to: '/system/users'
            },
            {
              name: i18n.t('nav.system.preferences'),
              to: '/'
            }
          ]
        }
      ]
    }
  ],
  ROLE_SSC: [
    {
      _name: 'CSidebarNav',
      _children: [
        // {
        //   _name: 'CSidebarNavItem',
        //   name: i18n.t('nav.dashboard'),
        //   to: '/dashboard',
        //   icon: 'cil-speedometer'
        // },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.ssc.ssc'),
          route: '/ssc',
          icon: 'cil-phone',
          items: [
            {
              name: i18n.t('nav.ssc.booking'),
              to: '/ssc/booking'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.dates'),
          to: '/events',
          icon: 'cil-calendar',
          items: [
            {
              name: i18n.t('nav.calendar'),
              to: '/events/calendar'
            },
            {
              name: 'Enrollments',
              to: '/events/appointments/enrollment'
            },
            {
              name: 'Sessions',
              to: '/events/appointments/session'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.franchise.franchise'),
          route: '/franchise',
          icon: 'cil-address-book',
          items: [
            {
              name: i18n.t('nav.franchise.list'),
              to: '/franchise/directory'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.leads.leads'),
          route: '/leads',
          icon: 'cil-list',
          items: [
            {
              name: i18n.t('nav.leads.list'),
              to: '/leads/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/leads/form'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.clients.clients'),
          route: '/clients',
          icon: 'cil-people',
          items: [
            {
              name: i18n.t('nav.clients.list'),
              to: '/clients/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/clients/form'
            },
            {
              name: i18n.t('nav.common.billing'),
              to: { name: 'BillingList' }
            }
          ]
        }
      ]
    }
  ],
  ROLE_DIRECTOR: [
    {
      _name: 'CSidebarNav',
      _children: [
        // {
        //   _name: 'CSidebarNavItem',
        //   name: i18n.t('nav.dashboard'),
        //   to: '/dashboard',
        //   icon: 'cil-speedometer'
        // },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.dates'),
          to: '/events',
          icon: 'cil-calendar',
          items: [
            {
              name: i18n.t('nav.calendar'),
              to: '/events/calendar'
            },
            {
              name: 'Enrollments',
              to: '/events/appointments/enrollment'
            },
            {
              name: 'Sessions',
              to: '/events/appointments/session'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.leads.leads'),
          route: '/leads',
          icon: 'cil-list',
          items: [
            {
              name: i18n.t('nav.leads.list'),
              to: '/leads/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/leads/form'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.clients.clients'),
          route: '/clients',
          icon: 'cil-people',
          items: [
            {
              name: i18n.t('nav.clients.list'),
              to: '/clients/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/clients/form'
            },
            {
              name: i18n.t('nav.common.billing'),
              to: { name: 'BillingList' }
            }
          ]
        }
      ]
    }
  ],
  ROLE_EC: [
    {
      _name: 'CSidebarNav',
      _children: [
        // {
        //   _name: 'CSidebarNavItem',
        //   name: i18n.t('nav.dashboard'),
        //   to: '/dashboard',
        //   icon: 'cil-speedometer'
        // },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.dates'),
          to: '/events',
          icon: 'cil-calendar',
          items: [
            {
              name: i18n.t('nav.calendar'),
              to: '/events/calendar'
            },
            {
              name: 'Enrollments',
              to: '/events/appointments/enrollment'
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: i18n.t('nav.clients.clients'),
          route: '/clients',
          icon: 'cil-people',
          items: [
            {
              name: i18n.t('nav.clients.list'),
              to: '/clients/list'
            },
            {
              name: i18n.t('nav.common.form'),
              to: '/clients/form'
            },
            {
              name: i18n.t('nav.common.billing'),
              to: { name: 'BillingList' }
            }
          ]
        }
      ]
    }
  ],
  ROLE_TUTOR: [
    {
      _name: 'CSidebarNav',
      _children: [
        // {
        //   _name: 'CSidebarNavItem',
        //   name: i18n.t('nav.dashboard'),
        //   to: '/dashboard',
        //   icon: 'cil-speedometer'
        // },
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('nav.calendar'),
          icon: 'cil-calendar',
          to: '/events/calendar'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Sessions',
          icon: 'cil-list',
          to: '/events/appointments/session'
        },
        {
          _name: 'CSidebarNavItem',
          name: i18n.t('nav.clients.clients'),
          icon: 'cil-people',
          to: '/clients/list'
        }
      ]
    }
  ]
}