<template>
  <div class="c-app" style="position: relative">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main" style="position: relative">
          <CContainer fluid>
            <b-button
              v-if="isRefresh"
              class="mb-2"
              variant="info"
              size="large"
              @click="update"
              block
            >
              A New Update is Availailable!
            </b-button>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
    <UserProfile
      ref="user-profile"
      :user="userProfileDetails"
      @hide="$store.commit('SET_USER_PROFILE_ID', null)"
    />
    <BaseShepherd />
    <!-- @XXX not sure this is the correct approach -->
    <!-- MODALS -->
    <b-modal
      id="modal-franchise-details"
      size="lg"
      ok-title="Close"
      ok-only
      @hidden="
        $store.getters.viewFranchiseCodeNoReset || $store.dispatch('resetFranchise')
      "
      ><!-- :title="`#${franchise.franchiseCode}: ${franchise.mainArea} (${franchise.state})`" -->
      <!-- template v-if="typeof franchise !== 'indefined' && franchise.id" #modal-title>
      {{ `#${franchise.franchiseCode}: ${franchise.mainArea} (${franchise.state})` }}</template -->
      <FranchiseDetails :franchise-code="$store.getters.viewFranchiseCode" />
    </b-modal>
    <ModalGroups />
    <Modals />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import UserProfile from '@/components/user/UserProfile'
import Modals from '@/components/Modals'
import ModalGroups from '../views/groups/~ModalGroups.vue'
import FranchiseDetails from '@/views/franchise/FranchiseDetails'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    UserProfile,
    Modals,
    ModalGroups,
    FranchiseDetails,
  },
  data: () => ({
    registration: null,
    isRefresh: false,
    refreshing: false,
  }),
  computed: {
    ...mapGetters(['userProfileId', 'userProfile']),
    userProfileImage() {
      return this.userImage(this.userProfile)
    },
    userProfileDetails() {
      if (!this.userProfile.id) return {}
      return {
        id: this.userProfile.id,
        username: this.userProfile.username,
        name: `${this.userProfile.profile.fname} ${this.userProfile.profile.lname}`,
        active: this.userProfile.active,
        title: this.userProfile.group.name,
        email: this.userProfile.email,
        image: this.userProfileImage,
      }
    },
  },
  watch: {
    async userProfileId(val) {
      if (val) {
        await this.$store.dispatch('getUserProfile', val)
      }
    },
  },
  created() {
    document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, {
      once: true,
    })
  },
  methods: {
    appUpdateUI: function (e) {
      this.registration = e.detail
      this.isRefresh = true
    },
    update() {
      this.isRefresh = false
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        const path = this.$route.path
        window.location = path + '?updated=' + new Date().getTime()
      })
    },
  },
}
</script>
